import styles from "./styles.module.scss";
import Link from "next/link";
import { useContext, useRef } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useCore } from "@/core-nextv3/core/core";
import redirectTo from "@/bet-nextv1/utils/redirectTo";
import logo from "../../../../public/assets/img/logo.png"
import { AuthContext } from "@/bet-nextv1/context/AuthContext";
import { subTranslate } from "@/bet-nextv1/utils/subTranslate";
import { THEME_SETTING, TRANSFER_SETTING } from "@/setting/setting";
import { useSubCore } from "@/hooks/useSubCore";
import Marquee from "react-fast-marquee";

export const HeaderDesktop = ({ account, setOpenLoginModal, setTypeLogin, setDisableClose, handleLogoutClick, transferPoints }: any) =>
{
    const { t }                                                     = useTranslation();
    const router                                                    = useRouter();
    const { user, loading }                                         = useContext(AuthContext);
    const { awaitLoadingHTTP, setAwaitLoadingHTTP, isLoadingUser }  = useCore();
    const { setOpenMessageModal, setMessage, setMessageModalIcon, } = useSubCore()
    

    const handleLogoLink = async (path) => 
    {

        if ( router.asPath === "/") 
        {
            router.reload();
        }
        else 
        {
            await router.push(path);
        }
    }


    return (
        <div className={styles.headerDesktop}>
            <div className={styles.content}>
                {!THEME_SETTING.headerInMiddle && <div className={styles.menuLeft}>
                    <div className={styles.logoMenu}>
                        <a onClick={() => handleLogoLink("/")} className='logo'>
                            <img 
                                src={account?.logo?._url || logo} 
                                alt='logo' 
                            />
                        </a>
                    </div>

        
                </div>}
        
                <Marquee className={styles.marquee}
                    direction='left'
                    speed={85}>
                    
                    <p>{THEME_SETTING.scrollingText}</p>
                    {THEME_SETTING.scrollingText2 && <p>{THEME_SETTING.scrollingText2}</p>}
                </Marquee>
        
                <div className={styles.left}>
                    {user &&
                        <ul className={styles.mainMenu}>

                            {/* <div className={styles.subMenuGridItem}>
                                <CiClock1 />
                                <Clock />
                            </div> */}
                            <div className={styles.subMenuGridItem}>
                        
                                <a
                                    className={styles.title}
                                    onClick={() => redirectTo("/deposit?tabIndex=0", user, router, setOpenLoginModal)}
                                >
                                    <img src='/assets/img/deposito_yellow.png' alt='' />
                                    {user.name} 님
                                </a>
                            </div>

                            <div className={styles.subMenuGridItem}>
                        
                                <a
                                    className={styles.title}
                                    // onClick={() => redirectTo("/deposit?tabIndex=1", user, router, setOpenLoginModal)}
                                >
                                    <img src='/assets/img/payout_yellow.png' alt='' />
                                    {t("Montante retido")}
                                    <small>
                                        {user?.sport
                                            ? new Intl.NumberFormat("en").format(user?.sport)
                                            : 0}{" "}
                                    </small>
                                </a>
                            </div>

                            <div className={styles.subMenuGridItem}>
                        
                                <a
                                    className={styles.title}
                                    onClick={() => transferPoints()}
                                    // onClick={() => redirectTo("/", user, router, setOpenLoginModal)}
                                >
                                    <img src='/assets/img/movimentacao_yellow.png' alt='' />
                                    {t("point")}
                                    <small>
                                        {user?.points
                                            ? new Intl.NumberFormat("en").format(user?.points)
                                            : 0}{" "}
                                    </small>
                                </a>
                            </div>

                            <div className={styles.subMenuGridItem}>
                        
                                <a
                                    className={styles.title}
                                    // onClick={() => redirectTo("/deposit?tabIndex=3", user, router, setOpenLoginModal)}
                                >
                                    <img src='/assets/img/customer_yellow.png' alt='' />
                                    {subTranslate("Dinheiro do Cassino", t)}
                                    <small>
                                        {user?.casino
                                            ? new Intl.NumberFormat("en").format(user?.casino)
                                            : 0}{" "}
                                    </small>
                                </a>
                            </div>

                            {/* <div className={styles.subMenuGridItemButton}>
                         
                                <a
                                    className={styles.title}
                                    onClick={() => redirectTo("/deposit?tabIndex=4", user, router, setOpenLoginModal)}
                                >
                                    {t("Refresh")}
                                </a>
                            </div> */}

                            <div className={styles.subMenuGridItemButton}>
                        
                                <Link
                                    className={styles.title}
                                    href='/deposit/?tabIndex=2'
                                    // onClick={() => redirectTo("/event", user, router, setOpenLoginModal)}
                                >
                                    {/* <img src='/assets/img/event_yellow.png' alt='' /> */}
                                    {t("Transferencias")}
                                </Link>
                            </div>

                            {/* <div className={styles.subMenuGridItem}>
                        
                                <a
                                    className={styles.title}
                                    onClick={() => redirectTo("/deposit?tabIndex=7", user, router, setOpenLoginModal)}
                                >
                                    <img src='/assets/img/notice_yellow.png' alt='' />
                                    {t("Avisos / Regras")}
                                </a>
                            </div>

                            <div className={styles.subMenuGridItem}>
                        
                                <a
                                    className={styles.title}
                                    onClick={() => redirectTo("/deposit?tabIndex=5", user, router, setOpenLoginModal)}
                                >
                                    <img src='/assets/img/betting-history_yellow.png' alt='' />
                                    {t("Histórico de Apostas")}
                                </a>
                            </div>

                            <div className={styles.subMenuGridItem}>
                        
                                <a
                                    className={styles.title}
                                    onClick={() => redirectTo("/deposit?tabIndex=6", user, router, setOpenLoginModal)}
                                >
                                    <img src='/assets/img/cash-history_yellow.png' alt='' />
                                    {t("Histórico de Cash")}
                                </a>
                            </div> */}
                        </ul>
                    }

        
                    
                    {awaitLoadingHTTP === false && user ? (
                        <div className={styles.dashboarWrap}>
            
                            <button onClick={() => handleLogoutClick()}>{t("Logout")}</button>
                        </div>
                    ) : <div className={styles.menuBtnGrp}>
                        {!loading && !awaitLoadingHTTP && (
                            <>
                                <button
                                    className={styles.buttonNavigation}
                                    data-bs-toggle='modal'
                                    data-bs-target='#signupin'
                                    onClick={() => (
                                        setOpenLoginModal(true),
                                        setTypeLogin("login"),
                                        setDisableClose(false)
                                    )}
                                >
                                    <span>{t("Logar")}</span>
                                </button>

                                <button
                                    className={styles.buttonNavigationTow}
                                    data-bs-toggle='modal'
                                    data-bs-target='#signupin'
                                    onClick={() => (
                                        setOpenLoginModal(true),
                                        setTypeLogin("register"),
                                        setDisableClose(false)
                                    )}
                                >
                                    <span className='rela'>{t("Cadastrar")}</span>
                                </button>
                            </> 
                        )}
                    </div>}
                </div>
            </div>
        </div>
    );
}